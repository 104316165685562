.advanced-cropper-simple-line {
    @apply border-neutral-200 border-[1px]
}
.advanced-cropper-simple-handler-wrapper {
    @apply w-6 h-6
}
.advanced-cropper-simple-handler-wrapper--west-north {
    @apply translate-x-0 translate-y-0
}
.advanced-cropper-simple-handler-wrapper--east-south {
    @apply -translate-x-full -translate-y-full
}
.advanced-cropper-simple-handler-wrapper--west-south {
    @apply translate-x-0 -translate-y-full
}
.advanced-cropper-simple-handler-wrapper--east-north {
    @apply -translate-x-full translate-y-0
}

.advanced-cropper-simple-handler {
    @apply block relative transition-opacity duration-500 border-none bg-neutral-200 w-1 h-1 opacity-0
}
.advanced-cropper-simple-handler--west-north,
.advanced-cropper-simple-handler--east-south,
.advanced-cropper-simple-handler--west-south,
.advanced-cropper-simple-handler--east-north {
    @apply block w-4 h-4 bg-transparent opacity-80
}
.advanced-cropper-simple-handler--west-north {
    @apply border-l-2 border-t-2 border-solid border-neutral-200
}
.advanced-cropper-simple-handler--east-south {
    @apply border-r-2 border-b-2 border-solid border-neutral-200
}
.advanced-cropper-simple-handler--west-south {
    @apply border-l-2 border-b-2 border-solid border-neutral-200
}
.advanced-cropper-simple-handler--east-north {
    @apply border-r-2 border-t-2 border-solid border-neutral-200
}
.advanced-cropper-simple-handler--hover {
    @apply opacity-100
}

.advanced-cropper-circle-stencil__preview {
    @apply border-2 border-neutral-200/80 border-solid
}

.advanced-cropper-circle-stencil .advanced-cropper-simple-line {
    @apply border-neutral-200/30
}

.advanced-cropper-circle-stencil .advanced-cropper-simple-handler--west-north,
.advanced-cropper-circle-stencil .advanced-cropper-simple-handler--east-south,
.advanced-cropper-circle-stencil .advanced-cropper-simple-handler--west-south,
.advanced-cropper-circle-stencil .advanced-cropper-simple-handler--east-north {
    @apply opacity-40
}

.advanced-cropper-circle-stencil .advanced-cropper-simple-handler--hover {
    @apply opacity-100
}

.advanced-cropper-stencil-grid {
    @apply text-neutral-200/70
}